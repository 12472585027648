import React, { Component } from 'react';
import './aboutMe.css';

export default class AboutMe extends Component {
  render() {
    return (
        <section className="about-me" id="about">
          <div className="titles--about">
            <h2 className="section__title section__title--about">About me</h2>
            <p className="section__subtitle section__subtitle--about"> Developer based in SE London</p>
          </div>
          <div className="about-me__body">
            <p className="about-me__text">Hello! I'm <strong>Tom</strong>, a Full-stack developer based in <strong>London, UK</strong> with a Software Development Bootcamp and several years of experience making apps and websites under my belt.</p>
            <br></br>
            <p className="about-me__text">When I'm not <strong>creating software experiences</strong> I'm probably working as a sound engineer, playing board games or enjoying some Progressive Metal with my cat, <strong>Blue!</strong></p>
            <img src="img/blue.png" alt="Blue playing Cat-an" className="cat__img" />
            <p className="about-me__text">I'm always super down to discuss new work opportunities on <strong>cool projects</strong> so please don't hesitate to get in touch!</p>
          </div>
          <img src="img/longselfie.png" alt="Tom in the jungle" className="about-me__img" />
        </section>
      );
    }
};