import React, { useState } from 'react';
import './navbar.css';

const Navbar = () => {
  // to change burger classes
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  // to toggle burger menu
  const updateMenu = () => {
    if(!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("menu visible");
    } else {
      setBurgerClass("burger-bar unclicked");
      setMenuClass("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  }

  
  return(
    <div>
      <nav>
        <div>
          <h3 className="logo">BigTomCodes</h3>
        </div>  

        <div className="burger-menu" onClick={updateMenu} >
          <div className={burger_class} ></div>
          <div className={burger_class} ></div>
          <div className={burger_class} ></div>
        </div>
      </nav>

      <div className={menu_class}>
        <ul className="nav__list">
          <li>
            <a href="#home" className="nav__link" onClick={updateMenu}> 
              Home
            </a>
          </li>
          <li>
            <a href="#work" className="nav__link" onClick={updateMenu}>
              My Work
            </a>
          </li>
          <li>
            <a href="#about" className="nav__link" onClick={updateMenu}>
              About Me
            </a>
          </li>
          <li>
            <a href="#contact" className="nav__link" onClick={updateMenu}>
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}


export default Navbar;