import React, { Component } from 'react';
import Navbar from './components/navbar/navbar';
import Intro from './components/intro/intro';
import AboutMe from './components/about-me/about-me';
import MyWork from './components/my-work/my-work';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import './App.css';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <Intro />
        <MyWork />
        <AboutMe />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default App;