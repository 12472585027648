import React, { Component } from 'react';
import './intro.css';
 
export default class Intro extends Component {
  render() {
    return (
        <section className="intro" id="home">
            <div className="intro__titles">
              <h1 className="section__title section__title--intro">
                Hi, I'm <strong>Tom Simpson</strong>
              </h1>
              <p className="section__subtitle section__subtitle--intro">full-stack dev</p>
            </div>

            <div className="intro__text">
              <p>Welcome to my portfolio! I make websites and apps that are easy to use and nice to look at.</p>
            </div>
            
            <img src="img/selfie.png" alt="Tom Simpson smiling" className="intro__img" />
            <img src="img/squareselfie.png" alt="Tom Simpson smiling" className="intro__img-square" />
        </section>
      );
    }
  };
