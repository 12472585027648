import React from 'react';
import './contact.css';

export default function Contact() {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");

    function encode(data) {
        return Object.keys(data)
        .map(
            (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    }

    function handleSubmit(e) {
        e.preventDefault();
        fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", name, email, message }),
        })
        .then(() => alert("Message sent!"))
        .catch((error) => alert(error));
    }         

    return (
        <section className="contact" id="contact">
            <div className="form-container">
            <form
                netlify
                name="contact"
                onSubmit={handleSubmit}
                className="form"
            >
                <h2 className="form-heading">Hire me</h2>
                <div className="fields">
                    <div className="name-input">
                        <label htmlFor="name" className="form-label">
                        Name
                        </label>
                        <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-field form-field__name"
                        onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="email-input">
                        <label htmlFor="email" className="form-label">
                        Your Email
                        </label>
                        <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-field form-field__email"
                        onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="message-input">
                        <label htmlFor="message" className="form-label">
                        Message
                        </label>
                        <textarea
                        id="message"
                        name="message"
                        className="form-field form-field__message"
                        onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn form-submit">
                        Submit
                    </button>
                </div>
            </form>
            </div>
        </section>
    );
    }

  