import React, { Component } from 'react';
import './footer.css';

export default class Footer extends Component {
  render() {
    return (
        <footer className="footer">
          <a href="mailto:hello@bigtomcodes.com" className="footer__link">hello@bigtomcodes.com</a>
          <ul className="social-list">
            <li className="social-list__item">
              <a className="social-list__link" href>
                <i className="fa-brands fa-github" />
              </a>
            </li>
            <li className="social-list__item">
              <a className="social-list__link" href>
                <i className="fa-brands fa-linkedin" />
              </a>
            </li>
            <li className="social-list__item">
              <a className="social-list__link" href>
                <i className="fa-brands fa-dribbble" />
              </a>
            </li>
            <li className="social-list__item">
              <a className="social-list__link" href>
                <i className="fa-brands fa-codepen" />
              </a>
            </li>
          </ul>
        </footer>
      );
    }
  };