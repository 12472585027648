import React, { Component } from 'react';
import './myWork.css';

export default class MyWork extends Component {
  render() {
    return (

        <section className="my-work" id="work">
          <div className="titles--work">
            <h2 className="section__title section__title--work">Portfolio</h2>
            <p className="section__subtitle section__subtitle--work">A selection of my work</p>
          </div>
          
          <div className="portfolio">
            <a href="https://dndspellsearch.bigtomcodes.com/" className="portfolio__item">
              <div className="hover-text">
                <h3>D&D Spell Search</h3>
                <p>Built using React, HTML and CSS, this app pulls from an open source JSON API to provide a searchable, filterable game aid for the popular RPG, Dungeons & Dragons.</p>
              </div>
              <img src="img/dndSpellSearch.png" alt="" className="portfolio__img" />  
            </a>
            <a href="https://bigtompaints.bigtomcodes.com/" className="portfolio__item">
              <div className="hover-text">
                <h3>Big Tom Paints</h3>
                <p>Built using Next.js, React, Tailwind.css, Zustand and integrated with Stripe, here is an ecommerce app to advertise and sell my services as a commission painter for tabletop wargame minis.</p>
              </div>
              <img src="img/bigtompaints.png" alt="" className="portfolio__img" />
            </a>
            <a href="portfolio-item.html" className="portfolio__item">
              <img src="img/waves.jpg" alt="" className="portfolio__img" />
            </a>
          </div>
        </section>
      );
    }
  };
